<template>
    <div class="cart-footer">
        <ResultSum />
        <div class="btn-group">
            <div class="payment">
                <FormButton width="100%" @click="pay" :disabled="disableBtn" class="btn">Перейти к оплате</FormButton>
            </div>
            <div>
<!--                <FormButton width="100%" layout="outline">Добавить услугу</FormButton>-->
            </div>
        </div>
    </div>
</template>

<script>
import ResultSum from "@/components/Cart/ResultSum";
import FormButton from "@/components/Form/FormButton";
import {fire} from "@/helpers/analytics";

export default {
    name: "CartFooter",
    components: {
        FormButton,
        ResultSum
    },
    methods: {
        async pay() {
            this.$emit('validate');
            this.$store.commit('data/setDisableBtn', true);
            await this.$store.dispatch('data/salePayDoc');
            this.$store.commit('data/setDisableBtn', false);

            if (typeof ym === 'function') {
              ym(46084455, 'reachGoal', 'PAYMENT');
            }

            fire("buy", {});
        }
    },
  computed: {
    disableBtn() {
      return this.$store.state.data.disableBtn;
    }
  },
}
</script>

<style scoped>
.cart-footer {
    width: 100%;
}

.cart-footer .btn-group {
    margin: 0 -32px;
    padding: 16px 32px 32px;
    border-top: 1px solid #E9ECF0;
}

.payment {
    margin-bottom: 16px;
}

.btn:disabled {
  background-color: #DADDE2;
  cursor: not-allowed;
}
</style>