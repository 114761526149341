import axios from "axios";
import validation from "./validation";
import { getRoistatFromCookie } from "@/helpers/roistat";

function defaultState() {
    return {
        findGuestUrl: process.env.VUE_APP_URL_API + '/public-api/paydoc/find-or-create-guest',
        createOrderUrl: process.env.VUE_APP_URL_API + '/public-api/paydoc/sale-pay-doc',
        guest: null,
        payDoc: null,
        activateCertUrl: process.env.VUE_APP_URL_API + '/public-api/paydoc/activate',
        phoneCode: {
            urlGet: process.env.VUE_APP_URL_API + '/public-api/paydoc/send-phone-code',
            urlSend: process.env.VUE_APP_URL_API + '/public-api/paydoc/check-phone-code',
            code: ''
        },
        regionId: null,
        services: {},
        moneyCertificates: {},
        promos: {},
        certData: {
            certNum: '',
            certPin: '',
            certType: '0',
            deliveryType: 'pickup',
            parlour: '',
            address: '',
            apartment: '',
            entrance: '', // Номер подъезда,
            floor: '',
            flat: '',
            phone: '',
            email: '',
            customerName: '',
            comment: ''
        },
        utm: null,
        roistat: null, // number | null
        disableBtn: false
    };
}

/**
 * Модуль с данными формы
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        payDoc(state) { return state.payDoc; },
        getState(state) { return state; },

        getUtm(state) { return state.utm; },
        getRoistat(state) { return state.roistat; },

        /**
         * Get service count in cart by service id
         * @param {Object} state
         * @return function
         */
        getCountByServiceId(state) {
            return function(serviceId) { return state.services[serviceId] ? state.services[serviceId].count : 0; };
        },

        /**
         * Get money certificates count by price
         * @param {Object} state
         * @return function
         */
        getCertificatesCountByPrice(state) {
            return function(price) { return state.moneyCertificates[price] ? state.moneyCertificates[price].count : 0; };
        },
        getPromoCountByPromoId(state) {
            return function(promoId) { return state.promos[promoId] ? state.promos[promoId].count : 0; };
        },

        /**
         * Get service by id
         * @param {Object} state
         * @return function
         */
        getServiceById(state) {
            return function(serviceId) { return state.services[serviceId] ? state.services[serviceId] : null; }
        },

        /**
         * Get cart sum price
         * @param {Object} state
         * @return Number
         */
        cartSum(state) {
            let result = 0;
            for (let serviceId in state.services) {
                const service = state.services[serviceId];
                result += service.price * service.count;
            }
            for (let certId in state.moneyCertificates) {
                const cert = state.moneyCertificates[certId];
                result += cert.price * cert.count;
            }
            for (let promoId in state.promos) {
                const promo = state.promos[promoId];
                result += promo.price * promo.count;
            }
            return result;
        },

        cartSumWithDelivery(state, getters, rootGetters) {
            const cartSum = getters.cartSum;
            const freeDeliveryMinSum = rootGetters["cart/freeDeliveryMinSum"];
            if (state.certData.certType === '1' && state.certData.deliveryType === 'courier') return cartSum < freeDeliveryMinSum ? cartSum + rootGetters.cart.deliveryPrice : cartSum;
            return getters.cartSum;
        },

        /**
         * @param {*} state 
         * @returns *
         */
        cartItems(state) {
            const result = [];

            Object.values(state.services).map(pd => {
                return { count: pd.count, price: pd.price, isMoney: false }
            }).concat(
                Object.values(state.moneyCertificates).map(pdMoney => {
                    return { count: pdMoney.count, price: pdMoney.price, isMoney: true }
                }) ?? []
             ).concat(
                Object.values(state.promos).map(promo => {
                    return { count: promo.count, price: promo.price, time: promo.time, code: promo.promoType, isPromo: true, type: promo.type };
                }) ?? []
            ).map(payDoc => {
                for (let i = 0; i < payDoc.count; i++) {
                   result.push({
                       amount: payDoc.isPromo && payDoc.type['measure'] === 'time' ? payDoc.time : payDoc.price,
                       code: (payDoc.code) ? payDoc.code :
                       (Number(state.certData.certType) === 0 && payDoc.isMoney) ? 'TE' :
                       (Number(state.certData.certType) === 1 && payDoc.isMoney) ? 'TC' : 'EK'
                   });
                }
            });
            
            return result;
         },
 
        /**
         * Get cart service count
         * @param {Object} state
         * @return Number
         */
        cartServiceCount(state) {
            const serviceCount = Object.values(state.services).map(service => service.count).reduce((a, b) => a + b, 0);
            const certCount = Object.values(state.moneyCertificates).map(service => service.count).reduce((a, b) => a + b, 0);
            const promosCount = Object.values(state.promos).map(promo => promo.count).reduce((a, b) => a + b, 0);
            return  serviceCount + certCount + promosCount;
        },

        /**
         * Get chosen region id
         * @param {Object} state
         * @return String
         */
        region(state) { return state.regionId; },

        /**
         * Get certificate data
         * @param {Object} state
         * @return Object
         */
        certData(state) { return state.certData; },

        /**
         * Get chosen services
         * @param {Object} state
         * @return Array
         */
        services(state) { return Object.values(state.services); },

        /**
         * Get chosen money certificates array
         * @param {Object} state
         * @return Array
         */
        moneyCertificates(state) { return Object.values(state.moneyCertificates); },
        promos(state) { return Object.values(state.promos); },

        /**
         * Get phone (only digits)
         * @param {Object} state
         * @return String
         */
        phone(state) { return String(state.certData.phone).replaceAll(/\D/g, ''); },

        phoneCode(state) { return state.phoneCode.code; }
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },

        comment(context) {
            let result = context.rootGetters['regions/regionById'](context.getters.region).name + "\n\n";
            if (context.getters.services.length) {
                const servicesStr = Object.values(context.state.services).map((service, index) => {
                    const facility = context.rootGetters["facilities/getFacilityById"](service.id);
                    return `ПД: ${index + 1}. Услуга: ${facility.name} (${service.count} шт) (${service.timeStr}) (сумма: ${service.price})`
                }).join("\n");
                result += "Услуги:\nBATCH:\n"  + servicesStr + "\nBATCH-END!\n\n";
            }
            if (context.getters.moneyCertificates.length) {
                const moneyCert = Object.values(context.state.moneyCertificates).map(c => `Сертификат на сумму ${c.price} (${c.count} шт)`).join("\n");
                result += "Сертификаты на сумму:\n" + moneyCert + "\n\n";
            }
            if (context.getters.promos.length) {
                const promos = Object.values(context.state.promos).map(c => `${c.name} ${c.price} (${c.count} шт)`).join("\n");
                result += "Акции:\n" + promos + "\n\n";
            }
            if (Number(context.state.certData.certType) === 1 && context.state.certData.deliveryType === 'courier') {
                result += "Уточнения для доставки:\n" +
                    [
                        `Адрес: ${context.state.certData.address}`,
                        `Подъезд: ${context.state.certData.entrance}`,
                        `Этаж: ${context.state.certData.floor}`,
                        `Квартира: ${context.state.certData.flat}`,
                        `ФИО: ${context.state.certData.customerName}`
                    ].join("\n") + "\n\n";
            }
            if (Number(context.state.certData.certType) === 1 && context.state.certData.deliveryType === 'pickup') {
                result += "Адрес салона: " +
                    `г. ${context.rootGetters["regions/regionById"](context.getters.region).name}, ` +
                    `${context.rootGetters["parlour/getParlourById"](context.state.certData.parlour).address}\n\n`;
            }
            if (context.state.certData.comment.length) {
                result += "Комментарий пользователя:\n" + context.state.certData.comment;
            }
            return result;
        },
        /**
         * Set region
         * @param {Object} context
         * @param {String} regionId
         */
        setRegion(context, regionId) { context.commit('setRegion', regionId); },

        /**
         * Add service to cart
         * @param {Object} context
         * @param {String} serviceId
         * @param {Number} timeId
         */
        addService(context, {serviceId, timeId}) {
            const facility = context.rootGetters['facilities/facilities'].filter(f => f.id === serviceId)[0];
            const price = facility.price.filter(p => p.id === timeId)[0].price;
            const timeStr = context.rootGetters["facilities/facilitiesTimesArr"][timeId];
            context.commit('addService', {serviceId, timeId, price, timeStr});
        },

        /**
         * Add money certificate to cart
         * @param {Object} context
         * @param {Number} price
         */
        addMoneyCertificate(context, price) { context.commit('addMoneyCertificate', price); },
        addPromo(context, {promoId, promoTypeCode, price, promoObj}) { context.commit('addPromo', {promoId, promoTypeCode, price, promoObj}); },

        /**
         * Increment service by id
         * @param {Object} context
         * @param {String} serviceId
         */
        serviceIncrement(context, serviceId) { context.commit('serviceIncrement', serviceId); },

        /**
         * Decrement service by id
         * @param {Object} context
         * @param {String} serviceId
         */
        serviceDecrement(context, serviceId) { context.commit('serviceDecrement', serviceId); },

        /**
         * Increment money certificate by price
         * @param {Object} context
         * @param {Number} price
         */
        moneyCertIncrement(context, price) { context.commit('moneyCertIncrement', price); },

        /**
         * Decrement money certificate by price
         * @param {Object} context
         * @param {Number} price
         */
        moneyCertDecrement(context, price) {
            context.commit('moneyCertDecrement', price);
        },

        promoIncrement(context, promoId) { context.commit('promoIncrement', promoId); },
        promoDecrement(context, promoId) { context.commit('promoDecrement', promoId); },

        setCertData(context, {key, value}) { context.commit('setCertData', {key, value}); },

        /**
         * Remove cart item
         * @param {Object} context
         * @param {String} type Item type - service|certificate
         * @param itemId
         */
        removeCartItem(context, {type, itemId}) { context.commit('removeCartItem', {type, itemId}); },

        async getPhoneCode(context) {
            const data = { phone: context.getters.phone };
            let response = await axios.post(context.state.phoneCode.urlGet, data, {
                headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` }
            });
            response = response.data;
            return response.success && response.data;
        },

        async sendPhoneCode(context) {
            const data = { phone: context.getters.phone, code: context.state.phoneCode.code };
            try {
                let response = await axios.post(context.state.phoneCode.urlSend, data, {
                    headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` }
                });
                response = response.data;

                if (response.success) {
                    context.commit('setGuest', response.data);
                }
                return response.success;
            } catch {
                return false;
            }


        },

        async activateCert(context) {
            let data = context.state.certData.certNum.match(/^(?<num>(?<code>[A-Za-zА-Яа-яёЁ]+)\d+)$/);
            if (!data) {
                return false;
            }

            data = data.groups;
            data.pin = context.state.certData.certPin;
            data.guestId = context.state.guest.id;
            let response = await axios.post(context.state.activateCertUrl, data, {
                headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` }
            });
            response = response.data;
            if (response.success) {
                context.commit('setPayDoc', response.data);
            }
            return response.success;
        },

        /**
         * Set code from SMS
         * @param {Object} context
         * @param {String} code
         */
        setPhoneCode(context, code) { context.commit('setPhoneCode', code); },

        setUtm(context, utm) { context.commit('setUtm', utm); },
        setRoistat(context, roistat) {
            context.commit('setRoistat', roistat);
        },

        async findOrCreateGuest(context) {
            const data = { phone: context.getters.phone, email: context.state.certData.email, name: context.state.certData.customerName };
            // context.commit('setDisableBtn', true);
            let response = await axios.post(context.state.findGuestUrl, data, {
                headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` }
            });
            response = response.data;
            if (response.success) {
                context.commit('setGuest', response.data);
            }
            return response.success;
        },


        validateDataBeforeSale(context) {
            const certData = context.state.certData;
            if (!validation.phone(context.getters.phone) || !validation.email(certData.email)) return false;
            return Number(certData.certType) === 0 ||
                Number(certData.certType) === 1 &&
                (
                    certData.deliveryType === 'pickup' ||
                    certData.deliveryType === 'courier' && validation.address(certData.address) && certData.entrance.length && certData.floor.length && validation.name(certData.customerName)
                );
        },

        async salePayDoc(context) { //1spd
            if (!(await context.dispatch('validateDataBeforeSale'))) return false;
            const findGuest = await context.dispatch('findOrCreateGuest');
            if (!findGuest) { return false; }

            const isUrlWithParameters = window.location.href.includes('?');
            const roistat = context.getters.getRoistat ?? getRoistatFromCookie();

            let data = {
                "utm": context.getters.getUtm,
                "roistatVisit": roistat,
                "amount": context.getters.cartSumWithDelivery,
                "nominal": 0,
                "paymentType": "cashless",
                "guestId": context.state.guest.id,
                "returnUrl": window.location.href + (isUrlWithParameters ? '&' : '?') + "payment=1",
                "failUrl": window.location.href + (isUrlWithParameters ? '&' : '?') + "payment=0",
                "regionId": context.state.regionId,
                "widgetCertItems": context.getters.cartItems,
                "customHourCost": context.rootGetters['regions/regionCost'](context.state.regionId)
            };

            if (Number(context.state.certData.certType) === 1) {
                data.deliveryType = context.state.certData.deliveryType;
                const tomorrowDate = new Date(Date.now() + 3600 * 24 * 1000);

                /**
                 * Convert Date object to string yyyy-mm-dd
                 * @param {Date} date
                 * @return String
                 */
                let convertDate = (date) => {
                    let month = '' + (date.getMonth() + 1), day = '' + date.getDate(), year = date.getFullYear();
                    if (month.length < 2)  month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    return [year, month, day].join('-');
                };

                if (data.deliveryType === 'pickup') {
                    data.pickUpParlourId = context.state.certData.parlour;
                    data.pickupDate = convertDate(tomorrowDate);
                }
                if (data.deliveryType === 'courier') {
                    data.address = context.state.certData.address;
                    data.deliveryDateTime = convertDate(tomorrowDate);
                }
            }

            data.comment = await context.dispatch('comment');

            let response = await axios.post(context.state.createOrderUrl, data, {
                headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` }
            });

            response = response.data;
            
            if (response.success) {
                // context.commit('setDisableBtn', false);

                let isInIframe = false;

                try {
                    isInIframe = (window.self !== window.top);
                } catch (e) {
                    isInIframe = true;
                }

                if (!isInIframe) {
                    window.location.replace(response.data.formUrl);
                } else {
                    window.parent.postMessage(
                        JSON.stringify({
                            action: "widget-redirect",
                            target: response.data.formUrl,
                        }),
                        "*"
                    );
                }
            }
            return response.success;
        }
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },

        /**
         * Set region
         * @param {Object} state
         * @param {String} regionId
         */
        setRegion(state, regionId) { state.regionId = regionId; },

        /**
         * Add service to cart
         * @param {Object} state
         * @param {String} serviceId
         * @param {Number} timeId
         * @param {Number} price
         * @param {String|null} timeStr
         */
        addService(state, {serviceId, timeId, price, timeStr = null}) {
            state.services[serviceId] = {
                id: serviceId,
                timeId: timeId,
                count: 1,
                price: price,
                timeStr: timeStr
            };
        },

        addPromo(state, {promoId, promoTypeCode, price, promoObj}) {
            state.promos[promoId] = {
                id: promoId,
                name: promoObj.name,
                promoType: promoTypeCode,
                count: 1,
                price: price,
                type: promoObj.type,
                time: promoObj.time,
            };
        },

        /**
         * Add money certificate to cart
         * @param {Object} state
         * @param {Number} price
         */
        addMoneyCertificate(state, price) { state.moneyCertificates[price] = { price: price, count: 1 }; },

        /**
         * Increment service by id
         * @param {Object} state
         * @param {String} serviceId
         */
        serviceIncrement(state, serviceId) { state.services[serviceId].count++; },

        /**
         * Decrement service by id
         * @param {Object} state
         * @param {String} serviceId
         */
        serviceDecrement(state, serviceId) {
            if (--state.services[serviceId].count === 0) {
                delete state.services[serviceId];
            }
        },

        /**
         * Increment money certificate by price
         * @param {Object} state
         * @param {Number} price
         */
        moneyCertIncrement(state, price) { state.moneyCertificates[price].count++; },

        /**
         * Decrement money certificate by price
         * @param {Object} state
         * @param {Number} price
         */
        moneyCertDecrement(state, price) {
            if (--state.moneyCertificates[price].count === 0) {
                delete state.moneyCertificates[price];
            }
        },

        promoIncrement(state, promoId) { state.promos[promoId].count++; },
        promoDecrement(state, promoId) {
            if (--state.promos[promoId].count === 0) {
                delete state.promos[promoId];
            }
        },

        /**
         * Set certificate data
         * @param {Object} state
         * @param {String} key
         * @param value
         */
        setCertData(state, {key, value}) { state.certData[key] = value; },

        /**
         * Remove cart item
         * @param {Object} state
         * @param {String} type Item type - service|certificate
         * @param itemId
         */
        removeCartItem(state, {type, itemId}) {
            if (type === 'service') { delete state.services[itemId]; }
            if (type === 'certificate') { delete state.moneyCertificates[itemId]; }
            if (type === 'promo') { delete state.promos[itemId]; }
        },

        /**
         * Set code from SMS
         * @param {Object} state
         * @param {String} code
         */
        setPhoneCode(state, code) { state.phoneCode.code = code; },

        setGuest(state, guest) { state.guest = guest; },

        setPayDoc(state, payDoc) { state.payDoc = payDoc; },

        setUtm(state, utm) { state.utm = utm; },
        setRoistat(state, roistat) { state.roistat = roistat; },

        setDisableBtn(state, disableBtn) { state.disableBtn = disableBtn }

    }
}
